import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EtwinSupportDocuments } from '../models/upload-document-model';
import { LoginService } from './login.service';
@Injectable({
  providedIn: 'root',
})
export class UploadDocumentsService {
  baseApiUrl: string = environment.baseApiUrl;
  constructor(private http: HttpClient, private loginService: LoginService) {}

  addEtwinSupportDocuments(file: File, additionalParams: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    additionalParams.createdBy = this.loginService.currentUserValue.username;
    additionalParams.createdDate = new Date();
    if (additionalParams.documentType === undefined) {
      additionalParams.documentType = 'Guide';
    }
    Object.keys(additionalParams).forEach((key) => {
      formData.append(key, additionalParams[key]);
    });
    return this.http.post(
      this.baseApiUrl + 'api/EtwinSupportDocument/AddEtwinSupportDocuments',
      formData
    );
  }
  replaceEtwinSupportDocuments(
    file: File,
    additionalParams: any
  ): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    additionalParams.createdBy = this.loginService.currentUserValue.username;
    additionalParams.createdDate = new Date();
    if (additionalParams.documentType === undefined) {
      additionalParams.documentType = 'Guide';
    }
    Object.keys(additionalParams).forEach((key) => {
      formData.append(key, additionalParams[key]);
    });
    return this.http.post(
      this.baseApiUrl + 'api/EtwinSupportDocument/ReplaceEtwinSupportDocuments',
      formData
    );
  }
  getAllEtwinSupportDocuments(): Observable<EtwinSupportDocuments[]> {
    return this.http.get<EtwinSupportDocuments[]>(
      this.baseApiUrl + 'api/EtwinSupportDocument/GetAllEtwinSupportDocuments'
    );
  }
  getEtwinSupportDocumentsByButtonId(
    RibbonPageGroupButtonId: Number
  ): Observable<EtwinSupportDocuments[]> {
    return this.http.get<EtwinSupportDocuments[]>(
      this.baseApiUrl +
        'api/EtwinSupportDocument/GetEtwinSupportDocumentsByButtonId?RibbonPageGroupButtonId=' +
        RibbonPageGroupButtonId
    );
  }
  documentsDownload(fileUrl: string) {
    return this.http.get(
      this.baseApiUrl +
        'api/EtwinSupportDocument/DocumentsDownload?fileUrl=' +
        fileUrl,
      {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      }
    );
  }
  deleteEtwinSupportDocuments(
    documentId: number,
    path: string
  ): Observable<EtwinSupportDocuments[]> {
    return this.http.get<EtwinSupportDocuments[]>(
      this.baseApiUrl +
        'api/EtwinSupportDocument/DeleteEtwinSupportDocuments?documentId=' +
        documentId +
        '&path=' +
        path
    );
  }
}
