import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { EtwinSupportDocuments } from '../../models/upload-document-model';
import { AddLevelsService } from '../../services/add-levels.service';
import { RibbonPageGroup } from '../../models/ribbon-page-group-model';
import { RibbonPage } from '../../models/ribbon-page-model';
import { RibbonPageGroupButton } from '../../models/ribbon-page-group-button-model';
import { UploadDocumentsService } from '../../services/upload-documents.service.service';
import {
  ConfirmationService,
  MessageService,
  ConfirmEventType,
} from 'primeng/api';
import { PlatformLocation } from '@angular/common';
@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrl: './upload-documents.component.css',
  providers: [ConfirmationService, MessageService],
})
export class UploadDocumentsComponent implements OnInit {
  @ViewChild('file')
  myInputFileVariable: any;
  fileDocument: any;
  fileExt: any;
  otherParams: any;
  documentType: any;
  documentPath: any;
  id: number = 0;
  disableDocCasteDropdown: boolean = false;
  currentDate: any = new Date();
  ribbonPageList: RibbonPage[] | undefined;
  selectedRibbonPage: RibbonPage | undefined;
  ribbonPageGroupList: RibbonPageGroup[] | undefined;
  selectedRibbonPageGroup: RibbonPageGroup | undefined;
  ribbonPageGroupButtonList: RibbonPageGroupButton[] | undefined;
  selectedRibbonPageGroupBtton: RibbonPageGroupButton | undefined;
  parentsupportDocuments: any;
  uploadDocumentsForm: FormGroup = new FormGroup({
    ribbonPagename: new FormControl(''),
    ribbonPageGroupname: new FormControl(''),
    ribbonPageGroupButtonname: new FormControl(''),
    uploadDocumentName: new FormControl(''),
    rtype: new FormControl(''),
  });
  submitted = false;
  createdBy = '';
  constructor(
    private addLevelsService: AddLevelsService,
    private uploadDocumentService: UploadDocumentsService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private platformlocation: PlatformLocation,
    private confirmationService: ConfirmationService
  ) {
    history.pushState(null, '', location.href);
    this.platformlocation.onPopState(() => {
      history.pushState(null, '', location.href);
    });
  }
  confirmSaveDocuments() {
    this.confirmationService.confirm({
      message: 'Are you sure want to replace the old file or keep the new one?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.replaceEtwinSupportDocuments();
      },
      reject: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Rejected',
          detail: 'Process incomplete',
          life: 3000,
        });
      },
      key: 'positionDialog',
    });
  }
  showToastMessage(msg: string, type: string) {
    if (type == 'success')
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: msg,
      });
    if (type == 'error')
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: msg,
      });
  }
  ngOnInit(): void {
    this.uploadDocumentsForm = this.formBuilder.group({
      ribbonPagename: ['', Validators.required],
      ribbonPageGroupname: ['', Validators.required],
      ribbonPageGroupButtonname: ['', Validators.required],
      uploadDocumentName: ['', Validators.required],
      rtype: ['', Validators.required],
    });
    this.documentType = 'Guide';
    this.addLevelsService.getAllRibbonPages().subscribe({
      next: (ribbonPages) => {
        //console.log(ribbonPages);
        this.ribbonPageList = ribbonPages;
      },
      error: (response) => {
        console.log(response);
      },
    });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.uploadDocumentsForm.controls;
  }
  uploadFile = (files: any) => {
    this.fileExt = files[0].name.split('.').pop();
    if (files.length === 0) {
      this.myInputFileVariable.nativeElement.value = '';
      this.fileDocument = '';
      return;
    } else if (
      this.fileExt == 'html' ||
      this.fileExt == 'doc' ||
      this.fileExt == 'pdf' ||
      this.fileExt == 'docx'
    ) {
      this.fileDocument = <File>files[0];
    } else {
      this.showToastMessage('Invalid Document Type.', 'error');
      this.myInputFileVariable.nativeElement.value = '';
      this.fileDocument = '';
      this.fileExt = '';
      return;
    }
  };
  onrChange(e: any) {
    this.documentType = e.target.value;
  }

  replaceEtwinSupportDocuments() {
    this.submitted = true;
    if (this.uploadDocumentsForm.invalid) {
      return;
    }
    this.otherParams = {
      id: this.id,
      idRibbonsPage: this.selectedRibbonPage?.id,
      idRibbonsPageGroup: this.selectedRibbonPageGroup?.id,
      idRibbonsPageGroupButton: this.selectedRibbonPageGroupBtton?.id,
      documentType: this.documentType,
      documentPath: this.documentPath,
      createdBy: this.createdBy,
      updatedBy: this.createdBy,
    };

    this.uploadDocumentService
      .replaceEtwinSupportDocuments(this.fileDocument, this.otherParams)
      .subscribe({
        next: (addEtwinSupportDocumentsReq) => {
          console.log(addEtwinSupportDocumentsReq);
          this.showToastMessage('Documents Saved Successfully', 'success');
          this.uploadDocumentService.getAllEtwinSupportDocuments().subscribe({
            next: (uploadDocuments) => {
              this.parentsupportDocuments = uploadDocuments;
              this.parentsupportDocuments.forEach(function (item: any) {
                console.log(item);
                var pos = item.documentName.lastIndexOf('_');
                item.displyDocumentName =
                  item.documentName.substring(0, pos) + item.documentExt;
              });
              this.clearDocumentFields();
            },
            error: (response) => {
              if (response.status === 401) {
                this.showToastMessage('Unauthorized', 'error');
              }
            },
          });
        },
        error: (response) => {
          if (response.status === 401) {
            this.showToastMessage('Unauthorized', 'error');
          } else {
            this.showToastMessage('Error while Saving Documents', 'error');
          }
        },
      });
  }

  addEtwinSupportDocuments() {
    this.submitted = true;
    if (this.uploadDocumentsForm.invalid) {
      return;
    }
    this.otherParams = {
      id: this.id,
      idRibbonsPage: this.selectedRibbonPage?.id,
      idRibbonsPageGroup: this.selectedRibbonPageGroup?.id,
      idRibbonsPageGroupButton: this.selectedRibbonPageGroupBtton?.id,
      documentType: this.documentType,
      documentPath: this.documentPath,
      createdBy: this.createdBy,
      updatedBy: this.createdBy,
    };

    this.uploadDocumentService
      .addEtwinSupportDocuments(this.fileDocument, this.otherParams)
      .subscribe({
        next: (addEtwinSupportDocumentsReq) => {
          console.log(addEtwinSupportDocumentsReq);
          this.showToastMessage('Documents Saved Successfully', 'success');
          this.uploadDocumentService.getAllEtwinSupportDocuments().subscribe({
            next: (uploadDocuments) => {
              this.parentsupportDocuments = uploadDocuments;
              this.parentsupportDocuments.forEach(function (item: any) {
                console.log(item);
                var pos = item.documentName.lastIndexOf('_');
                item.displyDocumentName =
                  item.documentName.substring(0, pos) + item.documentExt;
              });
              this.clearDocumentFields();
            },
            error: (response) => {
              if (response.status === 401) {
                this.showToastMessage('Unauthorized', 'error');
              }
            },
          });
        },
        error: (response) => {
          if (response.status === 401) {
            this.showToastMessage('Unauthorized', 'error');
          } else if (response.error.text == 'Document Exist') {
            this.confirmSaveDocuments();
          } else {
            this.showToastMessage('Error while Saving Documents', 'error');
          }
        },
      });
  }
  onRibbonPageChange() {
    this.addLevelsService
      .getAllRibbonPageGroups(this.selectedRibbonPage?.id)
      .subscribe({
        next: (ribbonPageGroups) => {
          this.ribbonPageGroupList = ribbonPageGroups;
        },
        error: (response) => {
          if (response.status === 401) {
            this.showToastMessage('Unauthorized', 'error');
          }
        },
      });
  }
  onRibbonPageGroupChange() {
    this.addLevelsService
      .getAllRibbonPageGroupButtons(this.selectedRibbonPageGroup?.id)
      .subscribe({
        next: (ribbonPageGroupButtons) => {
          this.ribbonPageGroupButtonList = ribbonPageGroupButtons;
        },
        error: (response) => {
          if (response.status === 401) {
            this.showToastMessage('Unauthorized', 'error');
          }
        },
      });
  }
  clearDocumentFields() {
    this.selectedRibbonPage = undefined;
    this.selectedRibbonPageGroup = undefined;
    this.selectedRibbonPageGroupBtton = undefined;
    this.documentType = 'Guide';
    this.myInputFileVariable.nativeElement.value = '';
    this.disableDocCasteDropdown = false;
  }
  editDocumentEventHandler($event: any) {
    this.selectedRibbonPage = this.getRibbonPageById($event.idRibbonsPage);
    this.loadRibbonPageGroups($event.idRibbonsPage, $event.idRibbonsPageGroup);
    this.loadRibbonPageGroupButtons(
      $event.idRibbonsPageGroup,
      $event.idRibbonsPageGroupButton
    );
    this.id = $event.id;
    this.documentPath = $event.documentPath;
    this.documentType = $event.documentType;
    this.disableDocCasteDropdown = true;
    this.myInputFileVariable.nativeElement.value = '';
  }
  getRibbonPageById(id: number) {
    return this.ribbonPageList?.filter(function (node) {
      return node.id == id;
    })[0];
  }

  loadRibbonPageGroups(
    ribbonPageId: number,
    selectedIdRibbonPageGroup: number
  ) {
    this.addLevelsService.getAllRibbonPageGroups(ribbonPageId).subscribe({
      next: (ribbonPageGroups) => {
        this.ribbonPageGroupList = ribbonPageGroups;
        this.selectedRibbonPageGroup = this.getRibbonPageGroupById(
          selectedIdRibbonPageGroup
        );
      },
      error: (response) => {
        console.log(response);
      },
    });
  }

  loadRibbonPageGroupButtons(
    ribbonPageGroupId: number,
    selectedRibbonPageGroupId: number
  ) {
    this.addLevelsService
      .getAllRibbonPageGroupButtons(ribbonPageGroupId)
      .subscribe({
        next: (ribbonPageGroupButtons) => {
          this.ribbonPageGroupButtonList = ribbonPageGroupButtons;
          this.selectedRibbonPageGroupBtton = this.getRibbonPageGroupButtonById(
            selectedRibbonPageGroupId
          );
        },
        error: (response) => {
          console.log(response);
        },
      });
  }
  getRibbonPageGroupById(id: number) {
    return this.ribbonPageGroupList?.filter(function (node: any) {
      return node.id == id;
    })[0];
  }
  getRibbonPageGroupButtonById(id: number) {
    //this.onRibbonPageGroupChange();
    return this.ribbonPageGroupButtonList?.filter(function (node: any) {
      return node.id == id;
    })[0];
  }
}
